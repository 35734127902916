import axios from 'axios';
import React, { useEffect, useState } from 'react'
import Card from '@mui/material/Card';
import Document from '../../Images/Docs.png'
import DocumentViewer from '../Vendors/DocumentViewer';

function RadPayment({ radId }) {
    const [paymentInfo, setPaymentInfo] = useState(null)
    const [open, setOpen] = useState(false)

    const radPaymentInfo = async () => {
        const { REACT_APP_RAD_PAYMENT_INFO } = process.env
        const res = await axios.get(REACT_APP_RAD_PAYMENT_INFO + `${radId}`)
        if (res?.status === 200) {
            setPaymentInfo(res?.data)
        }
    }


    useEffect(() => {
        if (radId) {
            radPaymentInfo();
        }
    }, [radId]);


    return (
        <div className='details-container'>
            <div >

                <div class="card">
                    <div><strong>Account Holder Name :</strong></div>
                    <div>{paymentInfo?.payment_details?.name_of_account_holder}</div>
                </div>

                <div class="card">
                    <div><strong>Account Number :</strong></div>
                    <div>{paymentInfo?.payment_details?.account_number}</div>
                </div>

                <div class="card">
                    <div><strong>IFSC Number :</strong></div>
                    <div>{paymentInfo?.payment_details?.ifsc}</div>
                </div>
                <div class="card">
                    <div><strong>Account Type :</strong></div>
                    <div>{paymentInfo?.payment_details?.account_type}</div>
                </div>

                <div class="card">
                    <div><strong>Bank Name :</strong></div>
                    <div>{paymentInfo?.payment_details?.bank_name}</div>
                </div>
                <div class="card">
                    <div><strong>Billing Address :</strong></div>
                    <div>{paymentInfo?.payment_details?.billing_address}</div>
                </div>

            </div>

            <div style={{ width: '50%' }}>

                <div class="card">
                    <div><strong>Payment Date :</strong></div>
                    <div>{paymentInfo?.payment_details?.payment_date}</div>
                </div>

                <div class="card">
                    <div><strong>PAN Number & Document :</strong></div>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div>{paymentInfo?.payment_details?.pan}</div>
                        {paymentInfo?.payment_details?.pan_image && <div style={{ marginTop: '10px' }}>
                            <a href={paymentInfo?.payment_details?.pan_image} target='blank'>
                                <img width={60} src={Document} />
                            </a>
                        </div>
                        }
                    </div>
                </div>

                <div class="card">
                    <div><strong>Aadhar Document :</strong></div>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        {paymentInfo?.payment_details?.aadhar_image && <div style={{ marginTop: '10px' }}>
                            <a href={paymentInfo?.payment_details?.aadhar_image} target='blank'>
                                <img width={60} src={Document} />
                            </a>
                        </div>
                        }
                    </div>
                </div>
                <div class="card">
                    <div><strong>Check Image :</strong></div>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        {paymentInfo?.payment_details?.cheque_image && <div style={{ marginTop: '10px' }}>
                            <a href={paymentInfo?.payment_details?.cheque_image} target='blank'>
                                <img width={60} src={Document} />
                            </a>

                        </div>
                        }
                    </div>
                </div>



            </div>

            <DocumentViewer open={open} setOpen={setOpen} />
        </div>
    )
}

export default RadPayment
