import React, { createContext, useEffect, useRef, useState } from "react";
import "./App.css";
import Home from "./components/Home/Home";
import Hospitals from "./components/Hospital/Hospitals";
import HospitalDetails from "./components/Hospital/HospitalDetails";
import Radiologists from "./components/Radiologists/Radiologists";
import Invoices from "./components/Invoice/Invoices";
import Reports from "./components/Report/Reports";
import HospitalNotices from "./components/HospitalNotices/HospitalNotices";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { FaArrowRight } from "react-icons/fa6";
import CollectionsIcon from '@mui/icons-material/Collections';
import PaymentIcon from '@mui/icons-material/Payment';
import { MdOutlinePayment } from "react-icons/md";
import { MdOutlinePending } from "react-icons/md";
import { AiOutlineProject } from "react-icons/ai";
import { LiaProductHunt } from "react-icons/lia";
import { CgProfile } from "react-icons/cg";
import AuthErrorPop from './components/Util/AuthErrorPop';
import { IoPeopleSharp } from "react-icons/io5";
import {
    BrowserRouter as Router,
    Route,
    Link,
    Routes,
    useLocation,
    useNavigate,
} from "react-router-dom";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import "react-toastify/dist/ReactToastify.css";
import Login from "./Login/LoginPage";
import HomeIcon from "@mui/icons-material/Home";
import LocalHospitalTwoToneIcon from "@mui/icons-material/LocalHospitalTwoTone";
import ReceiptIcon from "@mui/icons-material/Receipt";
import RadioImage from "./Images/radiologist.png";
import ReportImage from "./Images/report.png";
import Notice from "./Images/notification.png";
import Person2Icon from "@mui/icons-material/Person2";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { IoTicketSharp } from "react-icons/io5";
import AddToPhotosIcon from "@mui/icons-material/AddToPhotos";
import logo from "./Images/5Clogo.png";
import RadiologistsDetails from "./components/Radiologists/RadiologistsDetails";
// import firebase from "./firebase";
import { firebase } from './permission';
import { getAnalytics ,logEvent} from 'firebase/analytics';
import { permissionList, checkAcessLevel } from "./permission";
import HospitalMainNotice from "./components/HospitalNotices/HospitalMainNotice";
import { checkSuperAdmin } from "./permission.js";
import Users from "./components/User/Users";
// import { getAuth } from "firebase/auth";
import Collection from "./components/Collection/Collection";
import CollectionMain from "./components/Collection/CollectionMain";
import PayerContent from "./components/Collection/PayerContent";
import { CollectionsPermission, HomePermission, HospitalPermission, InvoicesPermission, NoticePermission, RadiologistsPermission, ReportsPermission, Vendor,TicketMain } from "./Utils/RoutePermission";
import Vendors from "./components/Vendors/Vendors";
import VendorDetail from "./components/Vendors/VendorDetail";
import { useAuth0 } from "@auth0/auth0-react";
import Ticket from "./components/Ticket/Ticket";

export const UserContext = createContext();

const analytics= getAnalytics(firebase)

const logoImage =
    "https://cdn-jbheb.nitrocdn.com/AsVxWIEAzKrKgRNfaabQkGygxbJBMPLx/assets/images/optimized/rev-8b0f7ff/prodigi.ai/wp-content/uploads/2022/08/5C_logo_color-768x472.png";

function App() {
    const navigate = useNavigate();
    const { user, isAuthenticated, isLoading, logout } = useAuth0();
    const [accessList, setAccessList] = useState("");
    // const [emailData, setEncryptedData] = useState('');
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);
    //to handle the tabs of the hospitals section
    const [activeTab, setActiveTab] = useState();
    const [collectActive, setCollectActive] = useState()
    const [email, setMail] = useState("");
    const [user1, setUser] = useState();
    const location = useLocation();
    const [url, setUrl] = useState(location.pathname);
    const [isSuperAdmin, setSuperAdmin] = useState(false);
    const [collectExpand, setCollectionExpand] = useState(false);
    const [authPop, setAuthPop] = useState('')
    const [accessLevel,setAccessLevel]=useState([])
    const [level, setLevel] = useState('')
    const [apiState, setApiState] = useState({
        hospitallist: null,
        downloadhospital: null,
        send_invoice_whastapp: null,
        send_invoice_email: null,
        gmApi: null,
        billedApi: null,
        clientgm: null,
        radgm: null,
        radlist: null,
        invoicelist: null,
        report: null,
    });
    const collectRef = useRef();

    useEffect(() => {
        setUrl(location.pathname);
        // //console.log(window.location.href)
        let flag = 1
        if (url.toString().toLowerCase().includes("home")) {
            setActiveTab("Home");
        } else if (url.toString().toLowerCase().includes("hospitals")) {
            setActiveTab("Hospitals");
        } else if (url.toString().toLowerCase().includes("radiologists")) {
            setActiveTab("Radiologists");
        } else if (url.toString().toLowerCase().includes("invoices")) {
            setActiveTab("Invoices");
        } else if (url.toString().toLowerCase().includes("reports")) {
            setActiveTab("Reports");
        } else if (url.toString().toLowerCase().includes("hospital-notices")) {
            setActiveTab("HospitalNotices");
        } else if (url.toString().toLowerCase().includes("collections")) {
            setActiveTab("Collections");
            const pathnameParts = location.pathname.split('/');
            const tabname = pathnameParts[pathnameParts.length - 1];
            // handleExpandCollection()
            if (pathnameParts.length !== 3) {
                const tabname = pathnameParts[pathnameParts.length - 2];
                setCollectActive(tabname)
            } else {
                setCollectActive(tabname)
            }

            if (collectRef.current) {
                collectRef.current.style.display = 'block';
            }
            flag = 2
            // collectRef.current.style.display='block'
        } else if (url.toString().toLowerCase().includes("vendors")) {
            setActiveTab("Vendors");
        }
        else if (url.toString().toLowerCase().includes("tickets")) {
            setActiveTab("Tickets");
        }
        else {
            setActiveTab("Users");
        }

        if (flag === 1) {
            if (collectRef.current) {
                collectRef.current.style.display = 'none';
            }
        }
    });

    // const handleLogout = () => {
    //     firebase
    //         .auth()
    //         .signOut()
    //         .then(() => {
    //             // User is signed out.
    //         })
    //         .catch((error) => {
    //             console.error("Sign-out error:", error);
    //         });
    //     setIsLoggedIn(false);
    //     localStorage.removeItem("email");
    //     localStorage.removeItem("password");
    //     localStorage.removeItem("tabs");
    //     localStorage.removeItem("tabname");
    //     navigate("/login");
    // };
    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };
    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };
    const handleLogin = () => {
        setIsLoggedIn(true);
    };

    useEffect(() => {
        // Ensure that Analytics is initialized and use logEvent correctly
        if (analytics) {
            console.log('hellooooo')
            logEvent(analytics, 'page_view', {
                page_title: document.title,
                page_location: window.location.href,
                page_path: window.location.pathname,
            });
        }
    }, [analytics]);

    const navigateRoute = (value) => {
        navigate(`/${value}`)
    }

    const handleAccess = (page, action) => {
        //console.log(accessList);
        if (
            accessList?.Permission?.level?.toString().toLowerCase() === "admin" ||
            accessList?.Permission?.level?.toString().toLowerCase() === "super_admin"
        ) {
            return true;
        } else if (
            accessList?.Permission?.level?.toString().toLowerCase() ===
            "selected_permission"
        ) {
            if (accessList?.Permission.Pages[page].includes(action)) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    };

    useEffect(() => {
        // console.log(isAuthenticated, user)
    

        if (isAuthenticated) {
            setMail(user?.sub?.split('|')?.[2])
            let email1 =user?.sub?.split('|')?.[2]

            const data = checkAcessLevel().then(data => {
                setAccessLevel(data?.user)
                let send = data?.user?.filter(data => {
                    
                    if (email1 === data?.email) {
                        return {
                            data
                        }
                    }
                })
                // console.log(send)
                if (send.length > 0) {
                    // console.log(send[0])
                    setUser(send[0])
                }

            })

            if (localStorage.getItem('preventUrl')) {
                // window.location.href=localStorage.getItem('preventUrl')
                navigate(localStorage.getItem('preventUrl'))
                localStorage.removeItem('preventUrl')
            }
            else {
                navigate("/home")
            }
            
        } else {
            // console.log(url)
            // const data = checkAcessLevel().then(data => {
            //     setAccessLevel(data?.user)
            //     let send = data?.user?.filter(data => {
            //         if (email === data?.email) {
            //             return {
            //                 data
            //             }
            //         }
            //     })
            //     // console.log(send)
            //     if (send.length > 0) {
            //         // console.log(send[0])
            //         setUser(send[0])
            //     }

            // })
            if (url.toString().toLowerCase() !== "/") {
                localStorage.setItem('preventUrl', url)
            }
        }


        // const unsubscribe = firebase.auth().onAuthStateChanged((authUser) => {
        //   if (authUser) {
        //     // setUser(authUser);
        //     // //console.log(authUser._delegate.email)
        //     setMail(authUser._delegate.email);
        //     handleLogin();

        //     const userAccess = permissionList?.filter((data) => {
        //       if (data?.email === authUser?._delegate.email) {
        //         return data;
        //       }
        //     });


        //     const data = checkAcessLevel().then(data => {

        //       let send=data?.user?.filter(data=>{
        //           if(authUser?._delegate.email===data?.email){
        //               return {
        //                   data
        //               }
        //           }
        //       })
        //       console.log(send)
        //       if(send.length>0){
        //         console.log(send[0])
        //          setUser(send[0])
        //       }
        //       })
        //       .catch(error => {
        //           console.error('Error fetching data:', error);
        //       });

        //     //console.log(window.location)
        //     // setUser(userAccess[0]);
        //     if(window.location.href==='https://app.dhan.5cnetwork.com/' || window.location.href==='http://localhost:3000/'||
        //     window.location.href==='https://app.dhan.5cnetwork.com' || window.location.href==='http://localhost:3000'
        //     ){
        //       //console.log('helo')
        //        navigate('/home')
        //     }

        //     // //console.log(isSuperAdmin)
        //   } else {
        //     // setUser(null);
        //     let url=window.location.href
        //     if (!url.toString().toLowerCase().includes("login")) {
        //     // setPreventUrl(url)
        //     localStorage.setItem('preventUrl',url)
        //     }
        //     setIsLoggedIn(false);
        //     navigate("/login");
        //   }
        // });


    }, [isAuthenticated, user]);

    const handleExpandCollection = () => {
        setCollectionExpand((prev) => !prev);
        if (collectExpand) {
            collectRef.current.style.display = "none";
        } else {
            collectRef.current.style.display = "block";
        }
    };

    useEffect(() => {
        const handleSuperAdmin = async () => {
            const data = await checkSuperAdmin(email);
            //console.log(data);
            if (data?.length > 0) {
                setLevel(data[0]?.Permission?.level)
                if (
                    data[0]?.Permission?.level.toString().toLowerCase() === "super_admin"
                ) {
                    setSuperAdmin(true);
                } else {
                    setSuperAdmin(false);
                }
            }
            // }else{
            //   setSuperAdmin(false)
            // }
            //console.log(data[0]?.Permission?.level);
            setAccessList(data[0]);
        };

        if (email) {
            handleSuperAdmin();
        }
    }, [email])

    return (
        <UserContext.Provider value={{ user:user1, handleAccess, apiState, setApiState, setAuthPop }}>
            <div>
                {!isAuthenticated ?
                    (
                        /* <LoginPage onLogin={handleLogin} /> */
                        <Login onLogin={handleLogin} />

                    )
                    : (
                        <div className="app">
                            <div className={`sidebar ${isSidebarOpen ? "open" : "closed"}`}>
                                <div className="logo">
                                    <img
                                        src={logo}
                                        className={`${isSidebarOpen ? "main-logo" : "min-logo"}`}
                                        alt="Logo"
                                    />
                                </div>
                                <ul>
                                    <HomePermission level={level}  >
                                        <li
                                            className={`${isSidebarOpen
                                                    ? `tab ${activeTab === "Home" ? "active" : ""}`
                                                    : `tab-min ${activeTab === "Home" ? "active" : ""}`
                                                }`}
                                            onClick={() => {
                                                handleTabClick("Home")
                                                navigateRoute('home')
                                            }}
                                        >
                                            <Link to="/home">
                                                {isSidebarOpen ? "Home" : <HomeIcon></HomeIcon>}
                                            </Link>
                                        </li>
                                    </HomePermission>

                                    <HospitalPermission level={level}  >
                                        <li
                                            className={`${isSidebarOpen
                                                    ? `tab ${activeTab === "Hospitals" ? "active" : ""}`
                                                    : `tab-min ${activeTab === "Hospitals" ? "active" : ""}`
                                                }`}
                                            onClick={() => {
                                                {
                                                    handleTabClick("Hospitals")
                                                    navigateRoute('hospitals')
                                                }
                                            }}
                                        >
                                            <Link to="/hospitals">
                                                {isSidebarOpen ? (
                                                    "Hospitals"
                                                ) : (
                                                    <LocalHospitalTwoToneIcon></LocalHospitalTwoToneIcon>
                                                )}
                                            </Link>
                                        </li>
                                    </HospitalPermission>

                                    <RadiologistsPermission level={level} >
                                        <li
                                            className={`${isSidebarOpen
                                                    ? `tab ${activeTab === "Radiologists" ? "active" : ""}`
                                                    : `tab-min ${activeTab === "Radiologists" ? "active" : ""
                                                    }`
                                                }`}
                                            onClick={() => {
                                                navigateRoute('radiologists')
                                                handleTabClick("Radiologists")
                                            }}
                                        >
                                            <Link to="/radiologists">
                                                {isSidebarOpen ? (
                                                    "Radiologists"
                                                ) : (
                                                    <img
                                                        className="radio-image"
                                                        src={RadioImage}
                                                        alt=""
                                                    ></img>
                                                )}
                                            </Link>
                                        </li>
                                    </RadiologistsPermission>

                                    <InvoicesPermission level={level} >
                                        <li
                                            className={`${isSidebarOpen
                                                    ? `tab ${activeTab === "Invoices" ? "active" : ""}`
                                                    : `tab-min ${activeTab === "Invoices" ? "active" : ""}`
                                                }`}
                                            onClick={() => {
                                                handleTabClick("Invoices")
                                                navigateRoute('invoices')
                                            }}
                                        >
                                            <Link to="/invoices">
                                                {isSidebarOpen ? "Invoices" : <ReceiptIcon></ReceiptIcon>}
                                            </Link>
                                        </li>
                                    </InvoicesPermission>

                                    <ReportsPermission level={level} >
                                        <li
                                            className={`${isSidebarOpen
                                                    ? `tab ${activeTab === "Reports" ? "active" : ""}`
                                                    : `tab-min ${activeTab === "Reports" ? "active" : ""}`
                                                }`}
                                            onClick={() => {

                                                handleTabClick("Reports")
                                                navigateRoute('reports')
                                            }}
                                        >
                                            <Link to="/reports">
                                                {isSidebarOpen ? (
                                                    "Reports"
                                                ) : (
                                                    <img
                                                        src={ReportImage}
                                                        className="radio-image"
                                                        alt=""
                                                    ></img>
                                                )}
                                            </Link>
                                        </li>
                                    </ReportsPermission>

                                    <NoticePermission level={level} >
                                        <li
                                            className={`${isSidebarOpen
                                                    ? `tab ${activeTab === "HospitalNotices" ? "active" : ""}`
                                                    : `tab-min ${activeTab === "HospitalNotices" ? "active" : ""
                                                    }`
                                                }`}
                                            onClick={() => {
                                                handleTabClick("HospitalNotices")
                                                navigateRoute('hospital-notices/notification')
                                            }}

                                        >
                                            <Link to="/hospital-notices/notification">
                                                {isSidebarOpen ? (
                                                    "Hospital Notices"
                                                ) : (
                                                    <img className="radio-image" src={Notice} alt=""></img>
                                                )}
                                            </Link>
                                        </li>
                                    </NoticePermission>

                                    <CollectionsPermission level={level} >
                                        <li
                                            className={`${isSidebarOpen
                                                    ? `tab ${activeTab === "Collections" ? "active" : ""}`
                                                    : `tab-min ${activeTab === "Collections" ? "active" : ""}`
                                                }`}
                                            onClick={() => {
                                                handleTabClick("Collections");
                                                navigateRoute('Collections')
                                                handleExpandCollection();
                                                setCollectActive('')
                                            }}
                                        >
                                            <Link to="/Collections">
                                                {isSidebarOpen ? (
                                                    "Collections"
                                                ) : (
                                                    <CollectionsIcon />
                                                )}
                                            </Link>
                                        </li>
                                    </CollectionsPermission>




                                    <div ref={collectRef} className={`${isSidebarOpen
                                            ? `collect-div`
                                            : `min-collect-div`
                                        }`}>
                                        <ul>
                                            <li

                                                onClick={() => {
                                                    handleTabClick("Collection-payer");
                                                    setCollectActive('payer')
                                                }}
                                            >
                                                <Link className="collect-tab-link" to="/Collections/payer">
                                                    {collectActive === 'payer' && <FaArrowRight style={{ marginRight: '10px', marginTop: '5px' }} />}
                                                    {isSidebarOpen ? (
                                                        "Payer"
                                                    ) : (
                                                        <CgProfile size={20} />
                                                    )}
                                                </Link>
                                            </li>
                                            {/* <li 
                        onClick={() => {
                          handleTabClick("Collection-payment");
                          setCollectActive('payment')
                        }}
                    >
                    <Link className="collect-tab-link" to="/Collections/payment">
                        { collectActive==='payment' && <FaArrowRight style={{marginRight:'10px',marginTop:'5px'}} /> }
                        {isSidebarOpen ? (
                          "Payment"
                        ) : (
                          // <PaymentIcon style={{width}} size={10} />
                          <MdOutlinePayment size={20} />
                        )}
                      </Link>
                    </li>
                    <li
                      onClick={() => {
                        handleTabClick("Collection-pending");
                        setCollectActive('pending')
                      }}
                    >
                       <Link className="collect-tab-link" to="/Collections/pending">
                        { collectActive==='pending' && <FaArrowRight style={{marginRight:'10px',marginTop:'5px'}} /> }
                        {isSidebarOpen ? (
                          "Pending"
                        ) : (
                          <MdOutlinePending size={20} />
                        )}
                      </Link>
                    </li>
                    <li
                      onClick={() => {
                        handleTabClick("Collection-projection");
                        setCollectActive('projection')
                      }}
                    >
                       <Link className="collect-tab-link" to="/Collections/projection">
                        { collectActive==='projection' && <FaArrowRight style={{marginRight:'10px',marginTop:'5px'}} /> }
                        {isSidebarOpen ? (
                          "Projection"
                        ) : (
                          <AiOutlineProject size={20} />
                        )}
                      </Link>
                    </li>
                    <li
                      onClick={() => {
                        handleTabClick("Collection-productivity");
                        setCollectActive('productivity')
                      }}

                    >
                       <Link className="collect-tab-link" to="/Collections/productivity">
                        { collectActive==='productivity' && <FaArrowRight style={{marginRight:'10px',marginTop:'5px'}} /> }
                        {isSidebarOpen ? (
                          "Productivity"
                        ) : (
                          <LiaProductHunt size={20} />
                        )}
                      </Link>
                    </li> */}
                                        </ul>



                                    </div>

                                    <Vendor level={level} >
                                        <li
                                            className={`${isSidebarOpen
                                                    ? `tab ${activeTab === "Vendors" ? "active" : ""}`
                                                    : `tab-min ${activeTab === "Vendors" ? "active" : ""}`
                                                }`}
                                            onClick={() => {
                                                handleTabClick("Vendors");
                                                navigateRoute('Vendors')
                                                handleExpandCollection();
                                                setCollectActive('')
                                            }}
                                        >
                                            <Link to="/Vendors">
                                                {isSidebarOpen ? (
                                                    "Vendors"
                                                ) : (
                                                    <IoPeopleSharp size={20} />
                                                )}
                                            </Link>
                                        </li>
                                    </Vendor>

                                    <TicketMain level={level} >
                                        <li
                                            className={`${isSidebarOpen
                                                    ? `tab ${activeTab === "Tickets" ? "active" : ""}`
                                                    : `tab-min ${activeTab === "Tickets" ? "active" : ""}`
                                                }`}
                                            onClick={() => {
                                                handleTabClick("Tickets");
                                                navigateRoute('Tickets')
                                                handleExpandCollection();
                                                setCollectActive('')
                                            }}
                                        >
                                            <Link to="/Tickets">
                                                {isSidebarOpen ? (
                                                    "Tickets"
                                                ) : (
                                                    <IoTicketSharp size={20} />
                                                )}
                                            </Link>
                                        </li>
                                    </TicketMain>

                                    {isSuperAdmin && (
                                        <li
                                            className={`${isSidebarOpen
                                                    ? `tab ${activeTab === "Users" ? "active" : ""}`
                                                    : `tab-min ${activeTab === "Users" ? "active" : ""}`
                                                }`}
                                            onClick={() => {

                                                handleTabClick("Users")
                                                navigateRoute('Users')
                                            }}
                                        >
                                            <Link to="/Users">
                                                {isSidebarOpen ? (
                                                    "Manage Users"
                                                ) : (
                                                    <AccountCircleIcon style={{ color: "white" }} />
                                                )}
                                            </Link>
                                        </li>
                                    )}
                                </ul>
                                <div className="collapse-icon">
                                    {/* {isSuperAdmin && <div><AccountCircleIcon style={{color:'white',marginBottom:'20px'}} /></div>} */}
                                    <i
                                        className={`fas fa-chevron-${isSidebarOpen ? "left" : "right"
                                            }`}
                                    />
                                    {isSidebarOpen ? (
                                        <ArrowBackIosNewIcon
                                            onClick={toggleSidebar}
                                            sx={{ color: "black", borderRadius: "50px" }}
                                        ></ArrowBackIosNewIcon>
                                    ) : (
                                        <ArrowForwardIosIcon
                                            onClick={toggleSidebar}
                                            sx={{ color: "black", borderRadius: "50px" }}
                                        ></ArrowForwardIosIcon>
                                    )}
                                </div>
                            </div>
                            <div className={`${isSidebarOpen ? "nav" : "min-nav"}`}>
                                {/* <div><MenuIcon className='menu-icon' /></div> */}
                                <nav>
                                    <div
                                        className={`${isSidebarOpen ? "user-maindiv" : "user-mindiv"
                                            }`}
                                    >
                                        <div className="user-icon">
                                            <div className="user-div">
                                                <ul>
                                                    <li>{email}</li>
                                                    <li onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}>Log Out</li>
                                                </ul>
                                            </div>
                                            <div className="user-icon-2">
                                                <Person2Icon
                                                    sx={{ backgroundColor: "white", borderRadius: "50px" }}
                                                ></Person2Icon>
                                            </div>
                                        </div>
                                    </div>
                                </nav>
                            </div>

                            {/* <createContext.Provider > */}
                            {/* <div className='main-div'>
             */}
                            <div
                                className={` ${isSidebarOpen ? "main-content" : "min-content"} `}
                            >
                                <Routes>
                                    {/* <Route exact path="/" element={<App/>}/> */}
                                    <Route exact path="/home" element={
                                        <HomePermission level={level} >
                                            <Home />
                                        </HomePermission>
                                    } />

                                    {/* <Route path='/' element={<App />} /> */}
                                    <Route
                                        path="/hospitals"
                                        element={
                                            <HospitalPermission level={level} >
                                                <Hospitals email={email} />
                                            </HospitalPermission>
                                        }
                                    />
                                    <Route
                                        path="/hospitals/:uniqueid/:tab"
                                        element={
                                            <HospitalPermission level={level}  >
                                                <HospitalDetails
                                                    email={email}
                                                    isSidebarOpen={isSidebarOpen}
                                                />
                                            </HospitalPermission>
                                        }
                                    />

                                    <Route
                                        path="/radiologists"
                                        element={
                                            <RadiologistsPermission level={level} >
                                                <Radiologists email={email} />
                                            </RadiologistsPermission>
                                        }
                                    />
                                    <Route
                                        path="/radiologists/:radid/:tab"
                                        element={
                                            <RadiologistsPermission level={level} >
                                                <RadiologistsDetails
                                                    isSidebarOpen={isSidebarOpen}
                                                    email={email}
                                                />
                                            </RadiologistsPermission>
                                        }
                                    />
                                    <Route
                                        path="/invoices"
                                        element={
                                            <InvoicesPermission level={level} >
                                                <Invoices isSidebarOpen={isSidebarOpen} email={email} />
                                            </InvoicesPermission>
                                        }
                                    />
                                    <Route
                                        path="/reports"
                                        element={
                                            <ReportsPermission level={level} >
                                                <Reports isSidebarOpen={isSidebarOpen} email={email} />
                                            </ReportsPermission>
                                        }
                                    />
                                    <Route
                                        path="/hospital-notices/:tab"
                                        element={
                                            <NoticePermission level={level} >
                                                <HospitalMainNotice
                                                    email={email}
                                                    isSidebarOpen={isSidebarOpen}
                                                />
                                            </NoticePermission>
                                        }
                                    />

                                    <Route
                                        path="/Collections/"
                                        element={
                                            <CollectionsPermission level={level} >
                                                <Collection email={email} isSidebarOpen={isSidebarOpen} />
                                            </CollectionsPermission>
                                        }
                                    />

                                    <Route
                                        path="/Collections/:tab"
                                        element={
                                            <CollectionsPermission level={level} >
                                                <CollectionMain email={email} isSidebarOpen={isSidebarOpen} />
                                            </CollectionsPermission>
                                        }
                                    />

                                    <Route
                                        path="/Collections/payer/:id"
                                        element={
                                            <CollectionsPermission level={level} >
                                                <PayerContent email={email} isSidebarOpen={isSidebarOpen} />
                                            </CollectionsPermission>
                                        }
                                    />

                                    <Route
                                        path="/Vendors/"
                                        element={
                                            <Vendor level={level} >
                                                <Vendors email={email} isSidebarOpen={isSidebarOpen} />
                                            </Vendor>
                                        }
                                    />

                                    <Route
                                        path="/vendors/:vendorid/:tab"
                                        element={
                                            <Vendor level={level} >
                                                <VendorDetail email={email} isSidebarOpen={isSidebarOpen} />
                                            </Vendor>
                                        }
                                    />

                                    <Route
                                        path="/tickets"
                                        element={
                                            <TicketMain level={level} >
                                                <Ticket email={email} isSidebarOpen={isSidebarOpen} accessLevel={accessLevel} />
                                            </TicketMain>
                                        }
                                    />


                                    {isSuperAdmin && (
                                        <Route
                                            path="/users"
                                            element={<Users isSidebarOpen={isSidebarOpen} />}
                                        />
                                    )}
                                    {/* <Route path="/Collections" element={<HospitalNotices email={email} isSidebarOpen={isSidebarOpen} />  } /> */}

                                    {/* <Route path="hospitals/:unique_id/PriceTab" element={<PriceTab />} /> */}
                                </Routes>
                            </div>

                            {/* </div> */}
                            {/* </createContext.Provider> */}

                            <AuthErrorPop authPop={authPop} setAuthPop={setAuthPop} />
                        </div>
                    )}
            </div>
        </UserContext.Provider>
    );
}

export default App;
export { createContext };
