import React, { useState, useEffect, useContext } from "react";
import "./Invoices.css";
import {
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  Button,
  Paper,
  TableSortLabel,
  Typography
} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import TextField from "@mui/material/TextField";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import ReadMoreIcon from "@mui/icons-material/ReadMore";
import axios from "axios";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import ClearIcon from "@mui/icons-material/Clear";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { makeStyles } from "@mui/styles";
import { Link, useNavigate } from "react-router-dom";
import TablePagination from "@mui/material/TablePagination";

import {
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarColumnsButton,
  GridToolbarQuickFilter,
  GridToolbarExport,
} from "@mui/x-data-grid";
import subMonths from "date-fns/subMonths";
import startOfMonth from "date-fns/startOfMonth";
import dayjs from "dayjs";
import * as XLSX from "xlsx";
import { IoLogoWhatsapp } from "react-icons/io";
import { HiOutlineMail } from "react-icons/hi";
import AuthErrorPop from "../Util/AuthErrorPop";
import { UserContext } from "../../App";
import ViewNote from "../Hospital/ViewNote";
import Alerts from "../Radiologists/Alerts";
import ApiResponsePopup from "../Hospital/ApiResponsePopup";
import { ErrorModel } from "../Hospital/TransactionsTab";
import Slide from "@mui/material/Slide";
import { DialogContentText } from "@mui/material";
import tick from "../../Images/tick.gif";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const useStyles = makeStyles((theme) => ({
  row: {
    "&:hover": {
      backgroundColor: "rgb(140, 140, 253)", // Add the hover background color here
      cursor: "pointer",
      transform: "scale(1.01)",
      // Change the cursor on hover if desired
    },
  },
  cell: {
    // Add any custom styles for the TableCell component here
  },
}));



export const dateBodyTemplate = (rowData) => {
  const formattedDate = new Date(rowData).toLocaleDateString("en-US", {
    day: "2-digit",
    month: "short",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });
  const datePart = formattedDate.slice(0, -8);
  const timePart = formattedDate.slice(-8);
  return (
    <div>
      <div className="date-part">
        {datePart}
        {timePart}{" "}
      </div>
      {/* <div className="time-part">{timePart}</div> */}
    </div>
  );
};

function Invoices({ isSidebarOpen, email }) {
  const navigate = useNavigate();
  const [showTable, setShowTable] = useState("");
  const [hospital, setHospital] = useState([]);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const [data, setData] = useState([]);
  const [mapData, setMapData] = useState([]);
  const [total, setTotal] = useState(0);
  const [listName, setListName] = useState(0);
  const [hospitalData, setHospitalData] = useState([]);
  const [seachRefresh, setRefresh] = useState(false);
  const [popTable, setPopTable] = useState([]);
  const { user } = useContext(UserContext);
  const { handleAccess, apiState, setApiState } = useContext(UserContext);
  const [authPop, setAuthPop] = useState(false);
  const [note, setNote] = useState(false);
  const [apiResponse, setApiResponse] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false);
  const [sendNote, setSendNote] = useState("");
  const [noteRes, setNoteRes] = useState("");
  const [error, setError] = useState("");
  const [noteDetails, setNoteDetails] = useState({
    pending_credit: 0,
    pending_debit: 0,
    total_credit: 0,
    total_debit: 0,
  });
  const [confirmationOpen,setConfirmationOpen]=useState('')

  const columns = [
    {
      field: "client_name",
      headerName: "Name",
      width: 90,
      flex: 1,
      minWidth: 150,
    },
    {
      field: "invoice_num",
      headerName: "Invoice Number",
      width: 90,
      flex: 1,
      minWidth: 150,
    },
    {
      field: "invoice_month",
      headerName: "Invoice Month",
      width: 90,
      flex: 1,
      minWidth: 150,
    },
    {
      field: "start_date",
      headerName: "Start Date",
      width: 90,
      flex: 1,
      minWidth: 150,
    },
    {
      field: "end_date",
      headerName: "End Date",
      width: 90,
      flex: 1,
      minWidth: 150,
    },
    {
      field: "invoice_sent_date",
      headerName: "Invoice Sent Date",
      width: 90,
      flex: 1,
      minWidth: 150,
    },
    {
      field: "first_invoice_amount",
      headerName: "First Invoice Amount",
      width: 90,
      flex: 1,
      minWidth: 150,
    },
    {
      field: "revision",
      headerName: "Revision",
      width: 90,
      flex: 1,
      minWidth: 150,
    },
    {
      field: "last_invoice_amount",
      headerName: "Last Invoice Amount",
      width: 90,
      flex: 1,
      minWidth: 150,
    },
    {
      field: "invoice_sent_by",
      headerName: "Sent By",
      width: 90,
      flex: 1,
      minWidth: 150,
    },
  ];

  const handleTotal = (data, column) => {
    const total = data?.reduce((total, row) => total + row[column], 0);
    return total;
  };

  const handleDownloadSummary = (data) => {
    //console.log(data);
    const datadownload = data?.summary_data?.map((data) => {
      return {
        "Invoice Details": data?.Details,
        Amount: data?.Amount,
      };
    });
    //console.log(datadownload);
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(datadownload);
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, `Download_Summary.xlsx`);
  };

  const handleSendNote = async (data) => {
    try {
      const body = [
        {
          clientId: data?.client_fk,
          startDate: data?.start_date?.split("T")[0],
          endDate: data?.end_date?.split("T")[0],
          invoiceDate: data?.invoice_sent_date?.split("T")[0],
          user_email: email,
        },
      ];
      //console.log(body);
      //  setError('Sent Note Failed')
      const { REACT_APP_CLIENT_SEND_NOTE } = process.env;
      const res = await axios.post(REACT_APP_CLIENT_SEND_NOTE, body);
      if (res?.data?.length > 0) {
        if (res.data[0] === "Email sent successfully") {
          setNoteRes(res?.data[1]);
        } else {
          setError("Sent Note Failed");
        }
      }
      //console.log(res);
    } catch (Err) {
      setError("Sent Note Failed");
    }
  };

  const handleMonth = (month) => {
    const [monthNumber, monthYear] = month.split(" ");
    return months[monthNumber - 1] + " " + monthYear;
  };
  const [selectedDate, setSelectedDate] = useState(null);
  const [searchMonth, setSearchMonth] = useState(null);
  const [search, setSearch] = useState("");
  const [refreshHospital, setRefreshHospital] = useState(false);
  const [invoiceCount, setInvoiceCount] = useState(0);
  const [popSearch, setPopSearch] = useState("");

  const [payable, setPayable] = useState("");
  const [firstInvoice, setFirstInvoice] = useState(null);
  const [revision, setRevision] = useState(null);

  const handleDateChange = (e) => {
    // setSelectedDate(e.target.value);
    //console.log(months[e.$d.getMonth()], e.$d.getFullYear());
    const monthYear = String(
      months[e.$d.getMonth()] + " " + String(e.$d.getFullYear())
    );
    //console.log(monthYear);
    //console.log(e);
    setSearchMonth(monthYear);
    setSelectedDate(e);
  };

  const handleFirstInvoice = () => {
    return hospitalData?.reduce(
      (total, row) => total + row.first_invoice_amount,
      0
    );
  };

  const intialDateFunc = () => {
    const initialDate = startOfMonth(subMonths(new Date(), 1));
    const formattedInitialDate = initialDate.toString();
    setSelectedDate(dayjs(formattedInitialDate));
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();
    // Calculate the previous month and year
    let previousMonth, previousYear;
    if (currentMonth === 0) {
      // If the current month is January (0), the previous month is December (11), and the year should be decreased by 1
      previousMonth = 11;
      previousYear = currentYear - 1;
    } else {
      // Otherwise, the previous month is the current month minus 1
      previousMonth = currentMonth - 1;
      previousYear = currentYear;
    }
    const prevMonth =
      String(months[previousMonth]) + " " + String(previousYear);
    setSearchMonth(prevMonth);
  };

  useEffect(() => {
    intialDateFunc();
  }, []);

  useEffect(() => {
    invoiceData();
    const total = data.reduce(
      (total, row) => total + row.last_invoice_amount,
      0
    );
    setTotal(total);
  }, []);

  useEffect(() => {
    fetchHospitalData();
    invoiceData();
  }, [searchMonth]);

  const invoiceData = async () => {
    setApiState((prevData) => ({
      ...prevData,
      invoicelist: "fetch",
    }));
    try {
      //console.log(searchMonth);
      let date = new Date(searchMonth);
      let month = date.getMonth() + 1; // Add 1 because months are zero-based
      let year = date.getFullYear();
      if (year === 1970) {
        return;
      }
      const { REACT_APP_CLIENT_SHOW_INVOICE_URL } = process.env;
      const res = await axios.get(
        REACT_APP_CLIENT_SHOW_INVOICE_URL + `?month=${month}&year=${year}`
      );
      //console.log(res.data.show_invoice_data.length, "length");
      const data = res.data.show_invoice_data.sort(function (a, b) {
        return (
          new Date(b.email_invoice_sent_date) -
          new Date(a.email_invoice_sent_date)
        );
      });
      setData(data);
      setNoteDetails({
        pending_credit: res?.data?.pending_credit,
        pending_debit: res?.data?.pending_debit,
        total_credit: res?.data?.total_credit,
        total_debit: res?.data?.total_debit,
      });
      setRefresh((prev) => !prev);
    } catch (err) {
      console.log(err);
    } finally {
      setApiState((prevData) => ({
        ...prevData,
        invoicelist: null,
      }));
    }
  };

  function formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Add 1 because months are zero-indexed
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  const fetchHospitalData = async () => {
    try {
      let previousMonth, previousYear;
      const currentDate = new Date();
      const splitMonth = searchMonth.split(" ");
      //console.log(splitMonth);
      previousMonth = months.indexOf(splitMonth[0]);
      previousYear = splitMonth[1];
      const firstDayOfPreviousMonth = new Date(previousYear, previousMonth, 1);
      // Calculate the last day of the previous month
      const lastDayOfPreviousMonth = new Date(
        previousYear,
        previousMonth + 1,
        0
      );
      const start_date = formatDate(firstDayOfPreviousMonth);
      const end_date = formatDate(lastDayOfPreviousMonth);
      //console.log(start_date, end_date);
      const { REACT_APP_CLIENT_LEVEL_DUMP_REPORT_URL } = process.env;
      const res = await axios.get(
        REACT_APP_CLIENT_LEVEL_DUMP_REPORT_URL +
          `?dump_name=Client_Level_Cost_Dump&start_date=${start_date}&end_date=${end_date}`
      );
      const data = res?.data?.data?.filter((data) => data.client_payable > 0);
      setHospital(data);
      setPayable(data?.reduce((total, row) => total + row.revised_cost, 0));

      setRefreshHospital((prev) => !prev);
    } catch (err) {}
  };

  const handleClear = () => {
    setSelectedDate(null);
    intialDateFunc();
    setSearch("");
    setRefresh((prev) => !prev);
  };

  useEffect(() => {
    const mappinData = data.filter((data, id) => {
      if (searchMonth === null) {
        return data;
      } else if (searchMonth === String(handleMonth(data.invoice_month))) {
        return data;
      }
    });
    //console.log(mappinData.length, "filterlength");
    const uniqueItems = new Map();

    mappinData.forEach((item) => {
      const id = item.client_fk;
      const timestamp = Date.parse(item.last_updated_at);

      if (
        !uniqueItems.has(id) ||
        timestamp > Date.parse(uniqueItems.get(id).last_updated_at)
      ) {
        uniqueItems.set(id, item);
      }
    });
    const uniqueList = Array.from(uniqueItems.values());
    setMapData(uniqueList);
  }, [searchMonth, seachRefresh]);

  useEffect(() => {
    let list;
    // setInvoiceSent(mapData.length)
    list = mapData.map((item) => item.client_fk);
    const total = mapData.reduce(
      (total, row) => total + row.last_invoice_amount,
      0
    );
    setTotal(total);
    setFirstInvoice(
      mapData.reduce((total, row) => total + row.first_invoice_amount, 0)
    );
    setRevision(mapData.reduce((total, row) => total + row.revision, 0));
    const mylist = [...new Set(list)];
    setInvoiceCount(mylist.length);
    const hospitalData = hospital?.filter((data, id) => {
      if (!mylist.includes(data.client_fk)) {
        return data;
      }
    });
    // console.log('data', hospitalData)
    setHospitalData(hospitalData);
  }, [mapData, refreshHospital]);

  const handleInvoiceDownload = () => {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(mapData);
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, `Invoice_list_${searchMonth}.xlsx`);
  };

  const handleDownload = () => {
    if (showTable === "count") {
      const data = popTable?.map((data) => {
        return {
          "Unique ID": data.client_unique_id
            ? data.client_unique_id
            : data.unique_id,
          "Client Name": data.client_name,
          "Billing Type": data.billing_type,
        };
      });
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet(data);
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
      XLSX.writeFile(wb, `${listName}_list.xlsx`);
    } else {
      const data = popTable
        ?.sort((a, b) => b[showTable] - a[showTable])
        .map((data) => {
          return {
            "Unique ID": data.client_unique_id
              ? data.client_unique_id
              : data.unique_id,
            "Client Name": data.client_name,
            Amount: data[showTable],
          };
        });
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet(data);
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
      XLSX.writeFile(wb, `${listName}_list.xlsx`);
    }
  };

  const navigateHospital = (unique_id) => {
    navigate(`/hospitals/${unique_id}/details`);
  };

  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [pagePop, setPagePop] = React.useState(0);
  const [rowsPerPagePop, setRowsPerPagePop] = React.useState(20);

  const handleChangePagePop = (event, newPage) => {
    setPagePop(newPage);
  };

  const handleChangeRowsPerPagePop = (event) => {
    setRowsPerPagePop(+event.target.value);
    setPagePop(0);
  };



  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("name");

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };

  const getComparator = (order, orderBy) => {
    return order === "desc"
      ? (a, b) => (b[orderBy] < a[orderBy] ? -1 : 1)
      : (a, b) => (a[orderBy] < b[orderBy] ? -1 : 1);
  };

  const handleConfirmInvoice =async(data)=>{
    console.log(data)
    const { REACT_APP_CLIENT_RESEND_INVOICE_URL } = process.env
    fetch(REACT_APP_CLIENT_RESEND_INVOICE_URL, {
      method: 'POST', // or 'GET' if appropriate
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),

    }).then(response => response.json())
      .then((response) => {
          if (response === null) {
            setErrorAlert('Invoice Note Failed')
          }
          else if (response[0]?.toString().toLowerCase() === 'Email sent successfully'.toString().toLowerCase()) {
            //console.log(response);
            //console.log('Invoice sent successfully!');
            setApiResponse("Invoice sent successfully")
          }
          else {
            setErrorAlert('Sent Invoice Failed')
            throw new Error('Network response was not ok');
          }
      })
      .catch((error) => {
          setErrorAlert('Sent Invoice Failed')
          console.error('Error sending the invoice:', error);
      })
      .finally(() => {
      });


       
  }

  return (
    <div className={`${isSidebarOpen ? "invoices-main" : "invoices-min"}`}>
      {/* <img className='page_img' src={Empty_image}></img> */}

      <div className={`${isSidebarOpen ? "invoice-div" : "invoice-div-min"}`}>
        <div className="invoice-header">
          <div
            style={{ fontSize: "25px", display: "flex", alignItems: "center" }}
          >
            Invoices
          </div>
          <div className="invoice-header-filter">
            <div>
              {" "}
              <Button
                variant="contained"
                style={{ fontSize: "12px" }}
                onClick={() => {
                  if (handleAccess("Invoices", "Download")) {
                    handleInvoiceDownload();
                  } else {
                    setAuthPop(true);
                  }
                }}
              >
                Download
              </Button>
            </div>
            <div>
              <TextField
                className="search-div"
                value={search}
                onChange={(event) => setSearch(event.target.value)}
                id="outlined-basic"
                label="Search"
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            {/* <div>  */}
            {/* <input type="month" id="start" className='monthInput' label='Select Month'  name="start"  /> */}
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                views={["month", "year"]}
                disabled={apiState?.invoicelist === "fetch"}
                label="Select Month and Year"
                value={selectedDate}
                onChange={handleDateChange}
                inputFormat="MM/yyyy"
                renderInput={(params) => (
                  <TextField className="monthPicker" value={selectedDate} />
                )}
              />
            </LocalizationProvider>
            {/* </div> */}
            <ClearIcon onClick={handleClear} />
          </div>
        </div>

        <div className="invoice-content">
          <div className="invoice-table">
            <Paper
              sx={{ overflow: "hidden" }}
              components={{
                Toolbar: () => {
                  return (
                    <GridToolbarContainer sx={{ justifyContent: "flex-end" }}>
                      <GridToolbarDensitySelector
                        sx={{ width: "100px", color: "black" }}
                      />
                      <GridToolbarColumnsButton
                        sx={{ margin: "10px", color: "black" }}
                      />
                      <GridToolbarQuickFilter />
                      <GridToolbarExport
                        sx={{ width: "100px", color: "black" }}
                      />
                    </GridToolbarContainer>
                  );
                },
              }}
            >
              <TableContainer
                component={Paper}
                className="invoice-container"
                style={{ height: "calc(100vh - 270px)" }}
              >
                <Table stickyHeader>
                  <TableHead>
                    <TableRow className="invoice-thead">
                      <TableCell>
                        <TableSortLabel
                          active={orderBy === "client_name"}
                          direction={orderBy === "client_name" ? order : "asc"}
                          onClick={() => handleRequestSort("client_name")}
                        >
                          Client Name
                        </TableSortLabel>
                      </TableCell>
                      <TableCell>
                        <TableSortLabel
                          active={orderBy === "invoice_num"}
                          direction={orderBy === "invoice_num" ? order : "asc"}
                          onClick={() => handleRequestSort("invoice_num")}
                        >
                          Invoice Number
                        </TableSortLabel>
                      </TableCell>
                      <TableCell>Month</TableCell>
                      <TableCell>Start Date</TableCell>
                      <TableCell>End Date</TableCell>
                      <TableCell>Invoice Sent Date</TableCell>
                      <TableCell
                      //  key={'first_invoice_amount'}
                      // sortDirection={orderBy === 'first_invoice_amount' ? order : false}
                      >
                        <TableSortLabel
                          active={orderBy === "first_invoice_amount"}
                          direction={
                            orderBy === "first_invoice_amount" ? order : "asc"
                          }
                          onClick={() =>
                            handleRequestSort("first_invoice_amount")
                          }
                        >
                          Invoice
                        </TableSortLabel>
                      </TableCell>
                     
                    
                      <TableCell>Net Payable</TableCell>
                      <TableCell>Note Sent</TableCell>
                      <TableCell>Pending Note</TableCell>
                      <TableCell>Sent By</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell>Whatsapp</TableCell>
                      <TableCell sx={{backgroundColor:'rgb(30 64 175)'}}>Status</TableCell>
                      <TableCell sx={{backgroundColor:'rgb(30 64 175)'}}>Balance</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {stableSort(mapData, getComparator(order, orderBy))
                      .filter((data, id) => {
                        if (
                          data.client_name
                            ?.toString()
                            .toLowerCase()
                            .includes(search?.toString().toLowerCase())||
                            data.invoice_num
                            ?.toString()
                            .toLowerCase()
                            .includes(search?.toString().toLowerCase())
                        ) {
                          return data;
                        }
                      })
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((data, id) => (
                        <TableRow key={id} className="invoice-tbody">
                          <TableCell>{data?.client_name}</TableCell>
                          <TableCell>
                            <div>
                              <div>
                            {data?.invoice_num}
                            </div>
                            <div>
                                <Button
                                  onClick={() => setConfirmationOpen(data)}
                                  variant="contained"
                                  style={{ marginTop: "5px", fontSize: "10px" }}
                                >
                                  Resend_Invoice
                                </Button>

                            </div>
                            </div>
                            
                            </TableCell>
                          <TableCell>
                            {handleMonth(data?.invoice_month)}
                          </TableCell>
                          <TableCell>
                            {data?.start_date.split("T")[0]}
                          </TableCell>
                          <TableCell>{data?.end_date.split("T")[0]}</TableCell>
                          <TableCell>
                            {data?.invoice_sent_date.split("T")[0]}
                          </TableCell>
                          <TableCell>
                            <div style={{ display: "flex", width: "100px" }}>
                              <div> </div>
                              <div>F - Rs . {data?.first_invoice_amount}</div>
                            </div>
                            <div style={{ display: "flex", width: "100px" }}>
                              <div> </div>
                              <div>R - Rs . {data?.revision}</div>
                            </div>
                            <div style={{ display: "flex", width: "100px" }}>
                              <div> </div>
                              <div>L - Rs . {data?.last_invoice_amount}</div>
                            </div>
                          </TableCell>
                        
           
                          <TableCell>
                            {data?.last_invoice_amount +
                              data?.sent_notes +
                              data?.pending_note}
                          </TableCell>

                          <TableCell>
                            <div>
                              <div> {data?.sent_notes}</div>

                              <div>
                                <Button
                                  onClick={() => setNote(data)}
                                  variant="contained"
                                  style={{ marginTop: "5px", fontSize: "10px" }}
                                >
                                  ViewNotes
                                </Button>
                              </div>
                            </div>
                          </TableCell>
                          <TableCell>
                            <div>
                              <div> {data?.pending_note}</div>

                              <div>
                                <Button
                                  onClick={() => {
                                    if (
                                      handleAccess("Invoices", "Send Note") ===
                                      true
                                    ) {
                                      setSendNote(data);
                                    } else {
                                      setAuthPop(true);
                                    }
                                  }}
                                  variant="contained"
                                  style={{ marginTop: "5px", fontSize: "10px" }}
                                >
                                  SendNote
                                </Button>
                              </div>
                            </div>
                          </TableCell>
                          <TableCell>{data?.invoice_sent_by}</TableCell>
                          <TableCell>
                            {data?.email_invoice_sent_date && (
                              <div>
                                <HiOutlineMail
                                  size={20}
                                  style={{ color: "red" }}
                                />
                                <div>
                                  {" "}
                                  {dateBodyTemplate(
                                    data?.email_invoice_sent_date
                                  )}
                                </div>
                              </div>
                            )}
                          </TableCell>
                          <TableCell>
                            {data?.watsapp_invoice_sent_date && (
                              <div>
                                <IoLogoWhatsapp
                                  size={20}
                                  style={{ color: "green" }}
                                />
                                <div>
                                  {" "}
                                  {dateBodyTemplate(
                                    data?.watsapp_invoice_sent_date
                                  )}
                                </div>
                              </div>
                            )}
                          </TableCell>
                          <TableCell  >
                               { data?.flag==='PAID' &&
                                <div style={{backgroundColor:'rgb(187 247 208)', padding:'2px',borderRadius:'3px' }} >{data?.flag}</div>
                                 }
                                   {data?.flag==='UNPAID' &&
                              <div style={{backgroundColor:'rgb(253 164 175)', padding:'2px',borderRadius:'3px' }} >{data?.flag}</div>
                               }
                               {data?.flag==='PARTIALLY PAID' &&
                              <div style={{backgroundColor:'rgb(226 232 240)', padding:'2px',borderRadius:'3px' }} >{data?.flag}</div>
                               }
                          </TableCell>
                          <TableCell   >
                            {new Intl.NumberFormat("en-IN", {
                              style: "currency",
                              currency: "INR",
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 0,
                            }).format(
                              data?.balance_amount
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TableContainer component={Paper}>
              <Typography variant="body2" align="end" style={{ padding: '8px' }}>
                Total Balance - {new Intl.NumberFormat("en-IN", {
                            style: "currency",
                            currency: "INR",
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          }).format(mapData?.reduce((total,data)=>total+data?.balance_amount,0))}
              </Typography>
            </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 20, 100]}
                component="div"
                count={mapData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </div>
          <div className="invoice-summary">
            <div>
              <div style={{ display: "flex" }}>
                <div
                  onClick={() => {
                    setShowTable("count");
                    setPopTable(hospital);
                    setListName("TotalClient");
                  }}
                  style={{ cursor: "pointer" }}
                  className="invoice-clients"
                >
                  <div style={{ fontSize: "1.2vw" }}>
                    {hospital?.length > 0 ? hospital?.length : "0"}
                  </div>
                  <div
                    style={{
                      color: "darkblue",
                      marginTop: "10px",
                      fontSize: "15px",
                    }}
                  >
                    Total Billed Clients
                  </div>
                </div>

                <div
                  onClick={() => {
                    setShowTable("count");
                    let list;
                    list = mapData.map((item) => item.client_fk);
                    const mylist = [...new Set(list)];
                    const hospitalData = hospital?.filter((data, id) => {
                      if (mylist.includes(data.client_fk)) {
                        return data;
                      }
                    });
                    setPopTable(hospitalData);
                    setListName("InvoiceClient");
                  }}
                  style={{ cursor: "pointer" }}
                  className="invoice-clients"
                >
                  <div style={{ fontSize: "1.2vw" }}>{invoiceCount}</div>
                  <div
                    style={{
                      color: "darkblue",
                      marginTop: "10px",
                      fontSize: "15px",
                    }}
                  >
                    No of Invoice Sent
                  </div>
                </div>
              </div>
              <div>
                {/* <div
                  onClick={() => {
                    setShowTable("revised_cost");
                    setPopTable(hospital);
                    setListName("TotalPayable");
                  }}
                  className="invoice-clients"
                >
                  <div style={{ fontSize: "1.2vw" }}>
                    <CurrencyRupeeIcon sx={{ marginBottom: '-7px' }} />
                    {new Intl.NumberFormat("en-IN", {
                      style: "currency",
                      currency: "INR",
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    }).format(Math.round(payable))}
                  </div>
                  <div
                    style={{
                      color: "darkblue",
                      marginTop: "10px",
                      fontSize: "15px",
                    }}
                  >
                    Total Payable
                  </div>
                </div> */}
                <div
                  onClick={() => {
                    setShowTable("count");
                    setPopTable(hospitalData);
                    setListName("PendingInvoice");
                  }}
                  style={{ cursor: "pointer" }}
                  className="invoice-clients"
                >
                  <div style={{ fontSize: "1.2vw" }}>
                    {hospital?.length - invoiceCount > 0
                      ? hospital?.length - invoiceCount
                      : "0"}
                  </div>
                  <div
                    style={{
                      color: "darkblue",
                      marginTop: "10px",
                      fontSize: "15px",
                    }}
                  >
                    Pending Invoices
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div style={{ display: "flex" }}>
                <div
                  onClick={() => {
                    setShowTable("first_invoice_amount");
                    setPopTable(mapData);
                    setListName("First_Invoice_Amount");
                  }}
                  className="invoice-clients"
                >
                  <div style={{ fontSize: "1.2vw" }}>
                    {/* <CurrencyRupeeIcon sx={{ marginBottom: '-7px' }} /> */}
                    {new Intl.NumberFormat("en-IN", {
                      style: "currency",
                      currency: "INR",
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    }).format(firstInvoice)}
                  </div>
                  <div
                    style={{
                      color: "darkblue",
                      marginTop: "10px",
                      fontSize: "15px",
                    }}
                  >
                    First Invoice Amount
                  </div>
                </div>

                <div
                  onClick={() => {
                    setShowTable("last_invoice_amount");
                    setPopTable(mapData);
                    setListName("Last_Invoice_Amount");
                  }}
                  className="invoice-clients"
                >
                  <div style={{ fontSize: "1.2vw" }}>
                    {/* <CurrencyRupeeIcon sx={{ marginBottom: '-7px' }} /> */}
                    {new Intl.NumberFormat("en-IN", {
                      style: "currency",
                      currency: "INR",
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    }).format(total)}
                  </div>
                  <div
                    style={{
                      color: "darkblue",
                      marginTop: "10px",
                      fontSize: "15px",
                    }}
                  >
                    Total Invoice Amount
                  </div>
                </div>
              </div>
              <div>
                <div
                  onClick={() => {
                    setShowTable("revision");
                    setPopTable(mapData);
                    setListName("Revision");
                  }}
                  className="invoice-clients"
                >
                  <div style={{ fontSize: "1.2vw" }}>
                    {/* <CurrencyRupeeIcon sx={{ marginBottom: '-7px' }} /> */}
                    {new Intl.NumberFormat("en-IN", {
                      style: "currency",
                      currency: "INR",
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    }).format(revision)}
                  </div>
                  <div
                    style={{
                      color: "darkblue",
                      marginTop: "10px",
                      fontSize: "15px",
                    }}
                  >
                    Revision
                  </div>
                </div>
               
              </div>
            </div>

            <div>
              <div style={{ display: "flex" }}>
                <div
                  onClick={() => {
                    setShowTable("send_debit_note");
                    setPopTable(mapData);
                    setListName("send_debit_note");
                  }}
                  style={{ cursor: "pointer" }}
                  className="invoice-clients"
                >
                  <div style={{ fontSize: "1.2vw",color:'#15803d' }}>
                    {new Intl.NumberFormat("en-IN", {
                      style: "currency",
                      currency: "INR",
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    }).format(
                      noteDetails?.total_debit
                      // handleTotal(mapData, "sent_notes")
                    )}
                  </div>
                  <div
                    style={{
                      color: "darkblue",
                      marginTop: "10px",
                      fontSize: "15px",
                    }}
                  >
                    Debit Note Sent
                  </div>
                </div>

                <div
                  onClick={() => {
                    setShowTable("send_credit_note");
                    setPopTable(mapData);
                    setListName("send_credit_note");
                  }}
                  style={{ cursor: "pointer" }}
                  className="invoice-clients"
                >
                  <div style={{ fontSize: "1.2vw",color:'#f43f5e' }}>
                    {new Intl.NumberFormat("en-IN", {
                      style: "currency",
                      currency: "INR",
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    }).format(
                      // handleTotal(mapData, "pending_note")
                      noteDetails?.total_credit
                    )}
                  </div>
                  <div
                    style={{
                      color: "darkblue",
                      marginTop: "10px",
                      fontSize: "15px",
                    }}
                  >
                    Credit Notes Sent
                  </div>
                </div>
              </div>
              <div>
                <div
                  onClick={() => {
                    setShowTable("net_invoice_payable");
                    setPopTable(mapData);
                    setListName("net_invoice_payable");
                  }}
                  style={{ cursor: "pointer" }}
                  className="invoice-clients"
                >
                  <div style={{ fontSize: "1.2vw" }}>
                    {new Intl.NumberFormat("en-IN", {
                      style: "currency",
                      currency: "INR",
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    }).format(
                      total +
                        noteDetails?.total_credit +
                        noteDetails?.total_debit
                    )}
                  </div>
                  <div
                    style={{
                      color: "darkblue",
                      marginTop: "10px",
                      fontSize: "15px",
                    }}
                  >
                    Net Invoice Payable
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div style={{ display: "flex" }}>
                <div
                  onClick={() => {
                    setShowTable("pending_debit_notes");
                    setPopTable(mapData);
                    setListName("pending_debit_notes");
                  }}
                  style={{ cursor: "pointer" }}
                  className="invoice-clients"
                >
                  <div style={{ fontSize: "1.2vw" ,color:'#15803d'}}>
                    {new Intl.NumberFormat("en-IN", {
                      style: "currency",
                      currency: "INR",
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    }).format(
                      noteDetails?.pending_debit
                      // handleTotal(mapData, "sent_notes")
                    )}
                  </div>
                  <div
                    style={{
                      color: "darkblue",
                      marginTop: "10px",
                      fontSize: "15px",
                    }}
                  >
                    Pending Debit Note
                  </div>
                </div>

                <div
                  onClick={() => {
                    setShowTable("pending_credit_notes");
                    setPopTable(mapData);
                    setListName("pending_credit_notes");
                  }}
                  style={{ cursor: "pointer" }}
                  className="invoice-clients"
                >
                  <div style={{ fontSize: "1.2vw",color:'#f43f5e' }}>
                    {new Intl.NumberFormat("en-IN", {
                      style: "currency",
                      currency: "INR",
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    }).format(
                      // handleTotal(mapData, "pending_note")
                      noteDetails?.pending_credit
                    )}
                  </div>
                  <div
                    style={{
                      color: "darkblue",
                      marginTop: "10px",
                      fontSize: "15px",
                    }}
                  >
                    Pending Credit Notes
                  </div>
                </div>
              </div>
              <div>
                <div
                  onClick={() => {
                    setShowTable("invoice_after_pending");
                    setPopTable(mapData);
                    setListName("invoice_after_pending");
                  }}
                  style={{ cursor: "pointer" }}
                  className="invoice-clients"
                >
                  <div style={{ fontSize: "1.2vw" }}>
                    {new Intl.NumberFormat("en-IN", {
                      style: "currency",
                      currency: "INR",
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    }).format(
                      total +
                        noteDetails?.total_credit +
                        noteDetails?.total_debit +
                        noteDetails?.pending_credit +
                        noteDetails?.pending_debit
                    )}
                  </div>
                  <div
                    style={{
                      color: "darkblue",
                      marginTop: "10px",
                      fontSize: "15px",
                    }}
                  >
                    Invoice After Pending
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <AuthErrorPop authPop={authPop} setAuthPop={() => setAuthPop(false)} />

        <Dialog
          open={showTable}
          onClose={() => {
            setShowTable("");
            setPopSearch("");
          }}
          aria-labelledby="responsive-dialog-title"
          sx={{ overflow: "auto" }}
          width={200}
        >
          <DialogTitle
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "550px",
              color: "darkblue",
            }}
            id="responsive-dialog-title"
          >
            Hospitals
            <div>
              <TextField
                className="search-div"
                value={popSearch}
                onChange={(event) => setPopSearch(event.target.value)}
                id="outlined-basic"
                label="Search"
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </DialogTitle>
          <div>
            {" "}
            <Button
              onClick={() => {
                if (handleAccess("Invoices", "Download")) {
                  handleDownload();
                } else {
                  setAuthPop(true);
                }
              }}
              style={{ float: "right", marginRight: "30px", fontSize: "11px" }}
              variant="contained"
              size="small"
            >
              Download
            </Button>
          </div>
          <DialogContent>
            <TableContainer
              component={Paper}
              className="invoice-container"
              style={{ height: "calc(100vh - 300px)" }}
            >
              <Table stickyHeader>
                <TableHead>
                  <TableRow className="invoice-thead">
                    <TableCell>UniqueId</TableCell>
                    <TableCell>Name</TableCell>
                    {showTable === "count" ? (
                      <TableCell>Billing Type</TableCell>
                    ) : (
                      <TableCell>Amount</TableCell>
                    )}
                    <TableCell>ReadMore</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {popTable
                    ?.filter((data, id) => {
                      if (
                        data.client_name
                          ?.toString()
                          .toLowerCase()
                          .includes(popSearch?.toString().toLowerCase())
                      ) {
                        return data;
                      }
                    })?.map(data=>{
                      if(showTable==='net_invoice_payable'){
                        return{
                          ...data,
                          net_invoice_payable:data?.last_invoice_amount +data?.sent_notes
                        }
                      }else if(showTable==='invoice_after_pending'){
                        return{
                          ...data,
                          invoice_after_pending:data?.last_invoice_amount +data?.sent_notes +data?.pending_note 
                        }
                      }
                      else{
                        return data
                      }
                    })
                    .sort((a, b) => {
                      if(showTable==='revision' ||showTable==='pending_credit_notes' || showTable==='send_credit_note'){
                        return a[showTable] - b[showTable];
                      }
                      else{
                      return b[showTable] - a[showTable];
                      }
                    })
                    .slice(
                      pagePop * rowsPerPagePop,
                      pagePop * rowsPerPagePop + rowsPerPagePop
                    )
                    .map((data, id) => (
                      <TableRow key={id} className="invoice-tbody">
                        <TableCell>
                          {data?.client_unique_id
                            ? data?.client_unique_id
                            : data?.unique_id}
                        </TableCell>
                        <TableCell>{data?.client_name}</TableCell>
                        {showTable === "count" ? (
                          <TableCell>{data?.billing_type}</TableCell>
                        ) : (
                          <TableCell>
    
                            {
                            new Intl.NumberFormat("en-IN", {
                              style: "currency",
                              currency: "INR",
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 0,
                            }).format(data[showTable])
                            }
                          </TableCell>
                        )}
                        <TableCell>
                          {/* <ArrowCircleRightIcon sx={{color:'blue',cursor:'pointer'}} onClick={()=>navigateHospital(data?.unique_id)} /> */}
                          <strong>
                            <Link
                              to={`/hospitals/${
                                data?.client_unique_id
                                  ? data?.client_unique_id
                                  : data?.unique_id
                              }/details`}
                              className="firstreadmoreIcon"
                            >
                              <ArrowCircleRightIcon
                                sx={{
                                  color: "blue",
                                  width: "80px",
                                  height: "30px",
                                }}
                              />{" "}
                            </Link>
                            <ReadMoreIcon
                              className="readmoreIcon"
                              style={{
                                fontSize: "36px",
                                display: "block",
                                margin: "auto",
                                color: "blue",
                              }}
                            />
                          </strong>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 20, 100]}
              component="div"
              count={popTable?.length}
              rowsPerPage={rowsPerPagePop}
              page={pagePop}
              onPageChange={handleChangePagePop}
              onRowsPerPageChange={handleChangeRowsPerPagePop}
            />
          </DialogContent>
          <DialogActions>
            {/* <Button autoFocus onClick={handleClose}>
            Disagree
          </Button>
          <Button onClick={handleClose} autoFocus>
            Agree
          </Button> */}
          </DialogActions>
        </Dialog>
        <ErrorModel data={errorAlert} onClose={() => setErrorAlert("")} />
        <ApiResponsePopup
          apiResponse={apiResponse}
          onClose={() => {
            setApiResponse("");
          }}
        />

          <Dialog open={confirmationOpen} onClose={() => setConfirmationOpen(false)}>
                            <DialogTitle>Confirmation</DialogTitle>
                            <DialogContent>
                                  {confirmationOpen.start_date && confirmationOpen.end_date && (
                                    <p>
                                      You are trying to Resend the invoices for {confirmationOpen.client_name} for the duration of{' '}
                                      {confirmationOpen?.start_date?.split("T")[0]} - {confirmationOpen?.end_date?.split("T")[0]}. Click 'YES' to confirm
                                      and Resend the invoice.
                                    </p>
                                  )}
                            </DialogContent>
                            <DialogActions>
                                  <Button onClick={() => setConfirmationOpen('')} color="primary">
                                    Cancel
                                  </Button>
                                  <Button onClick={() =>{ handleConfirmInvoice([{
                                    clientId:confirmationOpen?.client_fk,
                                    invoiceDate:confirmationOpen?.invoice_sent_date?.split("T")[0],
                                    startDate:confirmationOpen?.start_date?.split("T")[0],
                                    endDate:confirmationOpen?.end_date?.split("T")[0],
                                    user_email:email,
                                    send_self:'no',
                                  }])
                                  setConfirmationOpen('')
                                  }}
                                  
                                  color="primary" autoFocus>
                                    Yes
                                  </Button>
                            </DialogActions>
            </Dialog>

        <Dialog
          open={sendNote}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => setSendNote("")}
          aria-describedby="alert-dialog-slide-description"
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle>Confirmation !!!</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              Do you want to send the Note
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setSendNote("")}>Disagree</Button>
            <Button
              onClick={() => {
                // handleSendNote(resendNote)
                handleSendNote(sendNote);
                setSendNote("");
              }}
            >
              Agree
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={noteRes} onClose={() => setNoteRes("")}>
          <DialogContent style={{ width: "500px" }}>
            <div style={{ textAlign: "center" }}>
              <div style={{ marginTop: "20px" }}>
                <img style={{ width: "150px" }} src={tick} />
              </div>
              <div
                style={{ marginTop: "20px", fontSize: "18px", color: "gray" }}
              >
                {" "}
                Email Sent Successfully
              </div>

              <div>
                <Button
                  style={{ marginTop: "20px" }}
                  variant="contained"
                  onClick={() => handleDownloadSummary(noteRes)}
                  color="primary"
                >
                  Download Summary Data
                </Button>
              </div>

              <Button
                style={{ marginTop: "20px" }}
                variant="contained"
                onClick={() => setNoteRes("")}
                color="primary"
              >
                Close
              </Button>
            </div>
          </DialogContent>
        </Dialog>

        <Alerts warning={error} setWarning={setError} />

        <ViewNote
          note={note}
          setNote={setNote}
          setApiResponse={setApiResponse}
          setErrorAlert={setErrorAlert}
          email={email}
          handleAccess={handleAccess}
          setAuthPop={setAuthPop}
        />
      </div>
    </div>
  );
}

export default Invoices;
